/**
 * main.js
 *
 * @version 1.0.0
 * @copyright 2018 SEDA.digital GmbH & Co. KG
 */

/*
 * Please note that jQuery/$ is not available
 */


let WhenDOMReady = new Promise(function (resolve) {
    if (document.readyState !== 'loading')
        return resolve();
    else
        document.addEventListener('DOMContentLoaded', function () {
            return resolve();
        });
});

// menu helper functions
function hasClass(el, className) {
    return el.classList ? el.classList.contains(className) : new RegExp('\\b' + className + '\\b').test(el.className);
}

function addClass(el, className) {
    if (el.classList) el.classList.add(className);
    else if (!hasClass(el, className)) el.className += ' ' + className;
}

function removeClass(el, className) {
    if (el.classList) el.classList.remove(className);
    else el.className = el.className.replace(new RegExp('\\b' + className + '\\b', 'g'), '');
}

function whichTransitionEvent() {
    var t,
        el = document.createElement("fakeelement");

    var transitions = {
        "transition": "transitionend",
        "OTransition": "oTransitionEnd",
        "MozTransition": "transitionend",
        "WebkitTransition": "webkitTransitionEnd"
    }

    for (t in transitions) {
        if (el.style[t] !== undefined) {
            return transitions[t];
        }
    }
    return null;
}


// menu
import focusTrap from 'focus-trap';
let transitionEvent = whichTransitionEvent();
let openButton, closeButton, overlay, trap;

WhenDOMReady.then(function () {
    openButton = document.getElementById('menuOpen');
    closeButton = document.getElementById('menuClose');
    overlay = document.getElementById('menu');

    trap = focusTrap(overlay, {
        escapeDeactivates: false
    });

    if (openButton) {
        openButton.addEventListener('click', openMenu);
        closeButton.addEventListener('click', closeMenu);
        document.addEventListener('keydown', closeOnEsc);
    }
});

let openMenu = function () {
    if (!hasClass(overlay, 'is-open')) {
        addClass(overlay, 'c-menu--initialized');
        addClass(overlay, 'is-open');
        openButton.setAttribute('aria-expanded', 'true');
        overlay.setAttribute('aria-hidden', 'false');
        //overlay.focus();
        trap.activate();
        addClass(document.documentElement, 'u-noscroll');
    }
};

let closeMenu = function () {
    if (hasClass(overlay, 'is-open')) {
        removeClass(overlay, 'is-open');
        openButton.setAttribute('aria-expanded', 'false');
        overlay.setAttribute('aria-hidden', 'true');

        //openButton.focus();
        trap.deactivate();
        removeClass(document.documentElement, 'u-noscroll');

        if (transitionEvent !== null) {
            addClass(overlay, 'is-close');
            overlay.addEventListener(transitionEvent, transitionEventHandler);
        }
    }
};

let transitionEventHandler = function (event) {
    overlay.removeEventListener(transitionEvent, transitionEventHandler);
    removeClass(overlay, 'is-close');
}

// 'ESC' key handling for menu
let closeOnEsc = function (e) {
    if (hasClass(overlay, 'is-open') && e.keyCode === 27) {
        closeMenu();
    }
};

import Stickymenu from './components/stickymenu.js';
WhenDOMReady.then(function () {
    new Stickymenu();
});

// developer message
if (window.console) console.log(`%c 👋 Hey there! It's nice that you made it here.
If you are looking for a cool new job, take a look here:
https://seda.digital/jobs`, 'color: #0087C1; font-weight:bold; font-size: 1.25rem; line-height:2rem;');
