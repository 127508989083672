class Stickymenu {

    constructor() {
        let self = this;
        self.stickymenufunction();
    }

    whichTransitionEvent() {
        var t,
            el = document.createElement("fakeelement");

        var transitions = {
          "transition"      : "transitionend",
          "OTransition"     : "oTransitionEnd",
          "MozTransition"   : "transitionend",
          "WebkitTransition": "webkitTransitionEnd"
        }

        for (t in transitions){
          if (el.style[t] !== undefined){
            return transitions[t];
          }
        }
        return null;
    }

    stickymenufunction() {
        let menuButton = document.getElementById('menuOpen');
        let isSlidedIn = false;
        let cancelReset = false;
        let transitionEvent = this.whichTransitionEvent();

        // these functions handle the adding/removing of classes
        // function names indicate usage
        let prepareForSlideIn = function(){
            //console.log('prepare for slide in');
            menuButton.classList.add('is-outside');
            menuButton.classList.remove('is-sticky');
        }

        let slideIn = function(){
            //console.log('slide in');
            isSlidedIn = true;
            cancelReset = true;
            menuButton.classList.remove('is-outside');
            menuButton.classList.remove('is-slidingout');
            menuButton.classList.add('is-sticky');
        }

        let slideOut = function(){
            //console.log('slide out');
            menuButton.classList.remove('is-sticky');
            menuButton.classList.add('is-slidingout');

            cancelReset = false;
            if (transitionEvent !== null) {
                menuButton.addEventListener(transitionEvent, reset);
            }
        }

        let reset = function(){
            menuButton.removeEventListener(transitionEvent, reset);
            if (cancelReset === true) return;
            //console.log('reset');
            isSlidedIn = false;
            menuButton.classList.remove('is-slidingout');
            menuButton.classList.remove('is-sticky');
            menuButton.classList.remove('is-outside');
        }

        // requestAnimationFrame = request that the browser calls a specified function
        // to update an animation before the next repaint
        let requestAnimationFrame = window.requestAnimationFrame
        || window.mozRequestAnimationFrame
        || window.webkitRequestAnimationFrame
        || window.msRequestAnimationFrame
        || function(f){return setTimeout(f, 1000/60)};

        // add scroll handler with usage of requestAnimationFrame
        window.addEventListener('scroll', function() {
            // do not run for viewports wider than 1000px
            let w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            if (w > 1000) return;

            if (window.pageYOffset < 200 ) {
                requestAnimationFrame(reset);

            } else if (window.pageYOffset >= 200 && this.window.pageYOffset < 400) {
                if (isSlidedIn) {
                    requestAnimationFrame(slideOut);
                } else {
                    requestAnimationFrame(prepareForSlideIn);
                }

            } else if (window.pageYOffset >= 400) {
                requestAnimationFrame(slideIn);
            }
        });

        // handling initial state
        if (window.pageYOffset >= 400) {
            requestAnimationFrame(slideIn);
        }

    }
}

export default Stickymenu;
